import Icon from "UI/Icon"
import Analytics from "./pages/Analytics/Analytics"
import AnalyticsRoutes from "./pages/Analytics/Analytics.routes"
import RouteItem from "types/route-item"


const routes:RouteItem[] = [
    {
        route:{
            path:'',
            element:<Analytics/>,
            children: AnalyticsRoutes.map(({route}) => route)
        },
        title:'Аналитика',
        navBarIcon:<Icon icon="navigationAnalytics"/>
    },
    
]
export default routes