import React, { useContext, useState } from 'react'
import Icon from './Icon'
import { motion } from 'framer-motion'
import appContext from 'utils/app-context'

type Props = {}

const TopBar = (props: Props) => {
  const [opened, setopened] = useState(false)
  const {handleLogout} = useContext(appContext)
  return (
    <section className='top-bar'>
        <button className='top-bar-drop-down' onClick={() => setopened(!opened)}>
            <p>Уголок</p>
            <motion.span
            animate={{rotate: opened ? 0 : 180}}
            ><Icon icon='arrowTop'/></motion.span>
        </button>
        <motion.ul
        initial={false}
            animate={{opacity:Number(opened),y:opened ? 0 : 50}}
        className="drop-down-body">
            <li>
              <Icon icon='menuProfile'/>
              Профиль
            </li>
         <li onClick={handleLogout}>
              <Icon icon='menuExit'/>
              Выйти
            </li>
        </motion.ul>
    </section>
  )
}

export default TopBar