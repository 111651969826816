import Auth from "pages/Auth/Auth"
import Loading from "pages/Loading/Loading"
import Main from "pages/Main/Main"
import MainRoutes from "pages/Main/Main.routes"
import { RouteObject } from "react-router-dom"
import RouteItem from "types/route-item"


const routes:RouteItem[] = [
    {
        route:{
            path:'/',
            element:<Loading/>
        },
        title:'Загрузка'
    },
    {
        route:{
            path:'/auth',
            element:<Auth/>
        },
        title:'Авторизация'
    },
    {
        route:{
            path:'/main',
            element:<Main/>,
            children:MainRoutes.map(({route}) => route)
        },
        title:'Главная'
    },
   
]
export default routes