import React from 'react'
import { Outlet } from 'react-router-dom'
import NavigationBar from 'UI/NavigationBar'
import PageWrapper from 'UI/PageWrapper'
import routes from './Main.routes'
import styles from './Main.module.css'
import TopBar from 'UI/TopBar'
type Props = {}

const Main = (props: Props) => {
  return (
    <PageWrapper className={styles.page}>
      <NavigationBar routesList={routes}/>
        <div className={styles.pageContent}>
          <TopBar/>
          <Outlet/>
        </div>
    </PageWrapper>
  )
}

export default Main