import auth from 'api/auth/auth';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContextType from 'types/app-context';
import { User } from 'types/user';

export const useAppData = () => {
  const [user, setUser] = useState<User | null>(null); 
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token')); 
  const [language, setLanguage] = useState<string>('en');
  const navigate = useNavigate()
  const location = useLocation();
  
  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await auth(username, password);
      if(response && 'token' in response) {
        setIsAuthenticated(true);
        localStorage.setItem('token',response.token)
        navigate('/main')
      }
      return response
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    navigate('/')
  };

  const changeLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
  };

  const AppContextValue:AppContextType = {
    user,
    isAuthenticated,
    language,
    handleLogin,
    handleLogout,
    changeLanguage,
}

useEffect(() => {
  if(location.pathname === '/') return
  
  if (!isAuthenticated && location.pathname !== '/auth') {
    navigate('/auth', { replace: true });
  }
}, [isAuthenticated, location.pathname, navigate]);

  return {
    AppContextValue
  };
};
