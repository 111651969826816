import {
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React from "react";
import AppContext from "utils/app-context";
import { useAppData } from "hooks/use-app-data";

function App() {
  const navigation = useNavigate();
  const { AppContextValue } = useAppData();
  const element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();

  if (!element) return null;

  return (
    <AppContext.Provider value={AppContextValue}>
      <AnimatePresence mode="sync">
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
    </AppContext.Provider>
  );
}

export default App;
