import React from 'react'
import styles from './Analytics.module.css'
import Icon from 'UI/Icon'
import { Link } from "react-router-dom";
import routes from 'routes';
type Props = {}

const Analytics = (props: Props) => {
  return (
    <section>
      <h1>Аналитика</h1>
    </section>
  )
}

export default Analytics