import React from 'react'
import Icon from './Icon'
import RouteItem from 'types/route-item'
import { Link } from 'react-router-dom'

type Props = {
    routesList:RouteItem[]
}

const NavigationBar = ({routesList}: Props) => {
  return (
    <aside className='navigation'>
        <Link to={'/main'}><Icon icon='logoSwipOffice'/></Link>
        <nav>
            <ul>
              {routesList.map(({title,route,navBarIcon}) => (
                <li key={title}>
                    {navBarIcon}
                    <Link to={route.path || ''}>{title}</Link>
                </li>
              ))}
            </ul>
        </nav>
    </aside>
  )
}

export default NavigationBar