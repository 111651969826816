import Auth from "pages/Auth/Auth"
import Loading from "pages/Loading/Loading"
import { RouteObject } from "react-router-dom"
import Transactions from "./pages/Transactions/Transactions"
import RouteItem from "types/route-item"

const routes:RouteItem[] = [
    {
        route:{
            path:'transactions',
            element:<Transactions/>
        },
        title:'Загрузка'
    },
    
]
export default routes